@import 'src/styles/variables';

html,
body {
  height: 100%;
  margin: 0;
}

.app-loading-spinner {
  height: 100%;
  text-align: center;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .spinner-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-top: 8px;
  }

  .spinner-dot {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin: 0 4px;
  }

  .spinner-healthlabs {
    animation: spinnerHealthlabs 1s infinite linear alternate;
    background-color: #4ab873;
  }

  .spinner-stdcheck {
    animation: spinnerStdcheck 1s infinite linear alternate;
    background-color: #40729b;
  }

  .spinner-treatmyuti {
    animation: spinnerTreatmyuti 1s infinite linear alternate;
    background-color: $pearly-purple;
  }

  .spinner-starfish {
    animation: spinnerStarfish 1s infinite linear alternate;
    background-color: $pastel-red;
  }

  @for $i from 0 through 3 {
    .spinner-dot.dot-#{$i + 1} {
      animation-delay: $i * 0.25s;
    }
  }

  @keyframes spinnerHealthlabs {
    0% {
      background-color: #4ab873;
    }
    50%,
    100% {
      background-color: #40729b;
    }
  }

  @keyframes spinnerStdcheck {
    0% {
      background-color: #0099e1;
    }
    33% {
      background-color: #bcc3c8;
    }
    66%,
    100% {
      background-color: #fb8a00;
    }
  }

  @keyframes spinnerTreatmyuti {
    0% {
      background-color: $pearly-purple;
    }
    50%,
    100% {
      background-color: $pink-lavender;
    }
  }

  @keyframes spinnerStarfish {
    0% {
      background-color: $pastel-red;
    }
    50%,
    100% {
      background-color: $old-lace;
    }
  }
}
