$primary: #5b90b3 !default;
$secondary: #666666 !default;
$success: #4ab873 !default;
$info: #3398db !default;
$danger: #de4444 !default;
$light: #dce0e3 !default;
$dark: #303030 !default;
$highlight-blue: #e3ecf2 !default;
$warning: #e8cd20 !default;
$blue: #3398db !default;
$green-light: #ecf1e5;
$green: #809c58 !default;
$orange-light: #fceedc;
$orange: #ed9013 !default;
$grey: #bebebe !default;
$white: #fff;
$bright-gray: #e5e6f0;
$independence: #484e64;
$blue-yonder: #507fa3;
$box-shadow: rgba(120, 120, 120, 0.45);
$medium-violet-red: #b4127f;
$old-lace: #fff4e9;
$lotion: #fefcfa;

$chinese-white: #e2e9d7;
$light-chinese-white: #d3efdd;
$cultured: #f4f5f6;
$light-cultured: #f9faf6;
$metallic-blue: #355a73;
$milk: #fcfcf5;
$quick-silver: #a2a5a7;
$spartan-crimson: #9d120f;
$stormcloud: #545e64;

// Scale of gray
$bright-gray-rgba: rgba(230, 238, 244, 0.5);
$brigther-gray: #e6eef4;
$dark-gray: #7a8186;
$chinese-silver: #c9cbcc;
$ghost-white: #f5faff;
$chinese-black: #131516;
$chinese-black-rgba: rgba(19, 21, 22, 0.25);
$chinese-black-rgba-50: rgba(19, 21, 22, 0.5);
$dark-gunmetal: #15232d;
$cultured: #f7f6fa;
$quick-silver: #a2a5a7;
$clean-ghost-white: #f9f9f9;
$platinum: #e2e2e2;
$legacy-bright-gray: #efefef;
$light-gray: #d3d3d3;
$granite-gray: #5f5f5f;
$quartz: #4a4a4a;
$dark-charcoal: #2f2f2f;
$placeholder-gray: #d0d4da;
$athens-gray: #f4f5f7;
$jet-gray: #363636;

$anti-flash-white: #f0f0f0;
$gainsboro: #dddddd;
$raisin-black: #222222;

// Scale of blue
$pastel-blue: #a1bfd3;
$pastel-blue-rgba: rgba(161, 191, 211, 0.5);
$highlight-blue: #e3ecf2 !default;
$bdazzled-blue: #2a6290;
$teal-blue: #3d6884;
$lapis-lazuli: #256ca2;
$blue-sapphire: #1d5681;
$cornflower-blue: #2e88cc;
$cadet-blue: #adb5c8;
$police-blue: #3e5971;
$rackley: #588cac;

// Scale of green
$medium-sea-green: #42ab68;
$sea-green: #43ac6a;

// Scale of orange
$pastel-orange: #febd4f;
$cosmic-latte: #fcfde7;
$burlywood: #e3b77d;
$very-light-tangelo: #fcae84;
$apricot: #fdcfb6;
$persimmon: #f05906;
$tigers-eye: #e09339;
$light-apricot: #f2dac3;

// Scale of red
$pale-pink: #fcdfdf;
$treatmyuti-pale-pink: #fdd8d9;
$lavender-blush: #fff0f0;
$radical-red: #f72668;
$carmine-pink: #eb6262;
$pearly-purple: #b46397;
$pink-lavender: #cfb0c8;
$pastel-red: #fa676b;
$orange-red-crayola: #f84f55;

// Overwriting default desktop minimum width to fix breakpoint issues.
$desktop: 1150px;
// Adding a mobile breakpoint for min-width media queries
$mobile: 768px;

$navbar-breakpoint: 800px;

$default-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'highlight-blue': $highlight-blue,
);

$primary-colors: (
  blue: $primary,
  green: $success,
  red: $danger,
  dark: $dark,
  grey: $secondary,
  light: $light,
  highlight-blue: $highlight-blue,
  white: #f4f5f6,
);

$secondary-colors: (
  yellow: $warning,
  green: $green,
  orange: $orange,
  blue: $info,
  grey: $grey,
  chinese-silver: $chinese-silver,
);

$directions: ('top', 'right', 'bottom', 'left');

$navbar-breakpoint: 640px;

$global-radius: 6px;
$global-box-shadow: 1px 1px 5px 0 rgba(black, 0.1);

// Custom colors definition
$green-invert: findColorInvert($green);
$orange-invert: findColorInvert($orange);
$custom-colors: (
  'orange': (
    $orange,
    $orange-invert,
  ),
  'green': (
    $green,
    $green-invert,
  ),
);
$starfish-card-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
$starfish-color-filter: brightness(0) saturate(100%) invert(48%) sepia(69%) saturate(962%) hue-rotate(318deg)
  brightness(99%) contrast(97%);
$treatmyuti-color-filter: brightness(0) saturate(100%) invert(78%) sepia(9%) saturate(5231%) hue-rotate(283deg)
  brightness(73%) contrast(88%);
