.button {
  &.animated-left {
    overflow: hidden;
    border: none;

    &:hover:after {
      left: -3rem;
      transform: rotate(0deg);
    }

    &:after {
      transition: all 500ms;
      content: ' ';
      display: block;
      background-color: rgba(255, 255, 255, 0.3);
      width: 2.4rem;
      height: 200%;
      position: absolute;
      top: -10px;
      left: -5px;
      transform: rotate(-20deg);
    }
  }

  &.animated-right {
    overflow: hidden;
    border: none;

    &:hover:after {
      right: -3.2rem;
      transform: rotate(0deg);
    }

    &:after {
      transition: all 500ms;
      content: ' ';
      display: block;
      background-color: rgba(255, 255, 255, 0.3);
      width: 3.2rem;
      height: 200%;
      position: absolute;
      top: -10px;
      right: -10px;
      transform: rotate(20deg);
    }
  }

  &.medium-button {
    height: 2.3rem;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0 1.2rem;
  }

  &.large-button {
    @extend .medium-button;
    font-size: 1rem;
    height: 2.9rem;
  }
}

.right-align-text {
  padding-right: 1rem;
}

.left-align-text {
  padding-left: 1rem;
}
