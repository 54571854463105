@import 'src/styles/variables';
@import 'src/app/components/animated-button/animated-button.component';
@import 'bulma/sass/utilities/derived-variables';
@import 'bulma-extensions/bulma-calendar/src/sass/index';

.is-in-home {
  .findALabControls {
    display: none;
  }
}

findalab-results {
  flex-grow: 1;
  overflow-y: hidden;
}

.findALabControls {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  font-size: 16px;
  margin: 16px !important;

  br {
    display: none;
  }
}

.findalab__search {
  margin-bottom: 8px;
}

.locate_message {
  margin-bottom: 1rem !important;
  color: #fff;
  background-color: #eb2c27;
  border-radius: 0.375em;
  position: relative;
  padding: 0.5rem;
  font-size: 0.875rem;
}

app-healthlabs-layout {
  .findalab_searchForm {
    input {
      padding: 1rem !important;
    }
  }

  app-selected-center-card {
    h1 {
      margin-top: 1rem;
      color: $primary !important;
      font-size: 1rem !important;
    }

    button {
      float: right;
      margin-bottom: 1rem;
      box-shadow: 0 5px 5px 1px $platinum !important;
      font-weight: 700;
    }
  }

  .findalab__search__day-filter {
    input[type='radio']:checked ~ .findalab__radioButton {
      border: 2px solid $sea-green;
    }

    input[type='radio']:checked ~ .findalab__radioButton:before {
      background: $sea-green;
    }

    div.findalab__radioButton {
      border: 2px solid $sea-green;
    }
  }
}

app-healthlabs-layout.legacy-style {
  app-selected-center-card {
    button {
      font-weight: normal;
    }
  }
}

app-healthlabs-layout,
app-stdcheck-layout {
  .findALabControls {
    height: 915px !important;
  }

  findalab-results {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  findalab-results[hidden] {
    display: none;
  }

  .in-home-height {
    .findALabContainer {
      height: auto !important;
    }
  }

  .test-center {
    app-card .card {
      overflow: hidden;
    }

    .findALabContainer {
      width: calc(100% - 24px) !important;
      margin: 12px;
      margin-bottom: 0;
      height: 950px;
      border: solid $platinum 1px;
      border-radius: 6px;
      overflow: hidden;

      &.findalab-container-mobile {
        width: calc(100% - 24px) !important;
        border: none !important;
      }
    }

    .findALabMap {
      border-radius: 6px;
    }

    .findalab_searchForm {
      display: inline-flex;
    }

    .findalab-popup {
      font-family: 'Open Sans', Arial, sans-serif;
      font-size: 1em;
      font-weight: 400;
      line-height: 1.5;

      h4 {
        margin-bottom: 5px;
      }
    }

    .resultsContainer {
      height: 100% !important;

      .in-home-result-container {
        text-align: center;
        font-weight: bold;
        :nth-child(2) {
          font-size: 0.8rem;
          font-weight: normal;
        }

        button {
          border-radius: 8px;
        }
      }

      table.daysOfWeek {
        tbody {
          tr {
            td,
            th {
              div.closedForLunch {
                font-size: 0.68rem !important;
              }
            }
            th {
              padding-left: 0.5rem !important;
              padding-right: 0 !important;
            }
            td {
              padding-left: 0 !important;
              padding-right: 0.5rem !important;
              div {
                font-size: 0.8rem !important;
                text-align: right !important;
              }
            }
          }
        }
      }
    }

    .resultContainer:hover {
      background: $grey;
    }

    .result-recommended h4,
    .resultContainer h4,
    .findalab-popup h4 {
      font-size: large;
      font-weight: bold;
    }

    .result-recommended button,
    .resultContainer button,
    .findalab-popup-button {
      margin: 10px 0;
    }

    .findalab-controls-mobile {
      p {
        font-size: 0.9rem !important;
        margin: 2px !important;
      }

      .findalab_searchForm {
        width: 100%;
      }
    }

    .findalab__nav {
      border: 2px solid #3398db;
      margin-top: 8px;
      border-radius: 6px;
      font-size: 80%;

      .is-active {
        text-decoration: none;
        color: #fff;
        background: #3398db;
      }
    }

    .findalab__nav__item {
      border: none;
      color: #3398db;
      background-color: transparent;
      display: inline-block;
      padding: 8px;
      text-align: center;
      text-decoration: none;
      width: 50%;

      :first-child {
        float: left;
      }
    }

    .findalab__search__title {
      color: #131516;
      font-size: 1.625rem;
      margin-bottom: 0.3125rem;
      font-weight: 700;
    }

    .findalab__search__desc {
      margin: 0 0 0.5rem;
      font-size: 0.875rem;
      line-height: 1.6rem;
    }

    .findalab__search__user-location {
      background: $clean-ghost-white;
      border: 1px solid $platinum;
      border-radius: 6px;
      color: $dark-gray;
      line-height: 1rem;
      margin-bottom: 20px;
      padding: 10px 8px;
      font-size: 1rem;

      i {
        margin-right: 0.5rem;
      }
    }

    .findalab__search__day-filter {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      gap: 1rem;

      label {
        font-size: 1.125rem;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 0.2rem;
      }

      input[type='radio'] {
        position: absolute;
        visibility: hidden;
      }

      input[type='radio']:checked ~ .findalab__radioButton {
        border: 2px solid $orange;
      }

      input[type='radio']:checked ~ .findalab__radioButton:before {
        background: $orange;
      }

      div.findalab__radioButton {
        border: 2px solid $burlywood;
        border-radius: 100%;
        display: block;
        height: 20px;
        width: 20px;

        &::before {
          border-radius: 100%;
          content: '';
          display: block;
          height: 14px;
          top: 1px;
          position: relative;
          margin: auto;
          width: 14px;
        }
      }
    }

    .result-recommended,
    .result-container {
      padding: 1rem;

      &:hover {
        background: $platinum;
        transition: background 0.25s ease;
      }

      h4 {
        font-size: 1.5rem;
        font-weight: 700;
        color: $dark-gunmetal;
        margin-bottom: 0.5rem;
      }

      div {
        font-size: 1rem !important;
        color: $chinese-black;
      }

      button {
        margin: 1rem 0rem 1rem 0;
        background-color: $primary !important;
        border-radius: 8px;
        font-weight: 700;
        font-size: 1rem;
        height: 45px;
      }

      .openNowSection {
        padding: 1rem 0 0.7rem 0;

        .showHideHours {
          color: $blue;
          font-style: normal;
        }
      }

      table.daysOfWeek {
        margin: 0px;

        div {
          font-size: 15px !important;
        }
      }
    }

    .findalab_searchForm {
      input {
        border-radius: 0px;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        height: 2.5rem;
      }
      button.button {
        border: 1px solid transparent;
        border-radius: 0px !important;
        border-top-right-radius: 0.5rem !important;
        border-bottom-right-radius: 0.5rem !important;
      }
    }

    .in-home-address-container {
      background-color: white;
      padding: 1rem;

      button:hover {
        color: $white;
      }

      .in-home-address-title {
        text-align: center;
        font-size: 1.8rem;
        font-weight: bold;
      }

      .in-home-address-description {
        text-align: center;
        font-size: 0.8rem;
      }

      .in-home-address-line-1 {
        .error {
          color: $danger !important;
          font-size: small;
        }
      }

      .in-home-address-line-2 {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 2rem;
        .error {
          color: $danger !important;
          font-size: small;
        }

        > :nth-child(1) {
          width: 33%;
        }
        > :nth-child(2) {
          padding-left: 1rem;
          width: 34%;
        }
        > :nth-child(3) {
          padding-left: 1rem;
          width: 33%;
        }

        @media only screen and (max-width: $mobile) {
          > :nth-child(1) {
            width: 60%;
          }
          > :nth-child(2) {
            padding-left: 1rem;
            width: 40%;
          }
          > :nth-child(3) {
            padding-left: 0;
            width: 60%;
          }
        }

        select {
          height: 2.45rem;
        }
      }

      .in-home-address-line-3 {
        display: flex;
        column-gap: 1rem;
        justify-content: center;

        button {
          border-radius: 8px;
          width: 10rem;
        }
      }

      .in-home-address-error {
        .error {
          color: $danger !important;
          font-size: small;
        }
        text-align: center;
        margin: 1.5rem;
      }
    }

    .in-home-booking-container {
      background-color: white;
      padding: 1rem;

      button:hover {
        color: $white;
      }

      .in-home-booking-title {
        font-size: 1.5rem !important;
        font-weight: 700 !important;
        letter-spacing: 0.55px;
        line-height: 1.6rem;
        text-align: center;
        margin-bottom: 1rem;
      }

      .in-home-booking-address {
        text-align: center;
        font-size: 0.8rem;
        margin: 1rem;
      }

      .in-home-booking-date-and-slot {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        margin: 2rem 0;

        .in-home-booking-date {
          margin-bottom: 2rem;
          nwb-date-picker {
            float: right;

            .datepicker-nav-month {
              color: black;
              font-weight: bold;
            }

            .datepicker-nav-year {
              color: black;
              font-weight: bold;
            }

            .datepicker-nav-previous {
              color: black;
              background-color: white;
              margin: 0;
            }

            .datepicker-nav-next {
              color: black;
              background-color: white;
              margin: 0;
            }

            .datepicker-days .is-active {
              background-color: $orange !important;
              color: #fff !important;
            }

            .datepicker-days .is-today {
              color: #000 !important;
            }
          }
        }

        .in-home-booking-slot {
          .in-home-booking-slot-title {
            font-size: 1.5rem !important;
            font-weight: 700 !important;
            letter-spacing: 0.55px;
            line-height: 1.6rem;
            text-align: center;
            margin-bottom: 1rem;
          }

          .in-home-booking-slot-list {
            text-align: center;
            list-style-type: none;
            max-height: 240px;
            overflow-y: auto;

            button {
              width: 180px;
              margin-bottom: 15px;
              font-size: small;
            }
          }
        }
      }

      .in-home-booking-buttons {
        display: flex;
        column-gap: 1rem;
        justify-content: center;

        button {
          border-radius: 8px;
          width: 10rem;
        }
      }
    }

    div.gm-style-iw.gm-style-iw-c {
      max-width: 100% !important;
    }
  }

  @media only screen and (max-width: $mobile) {
    findalab-map {
      .findalab-map.findALabMap.map-view {
        position: relative;
        height: 500px !important;
      }

      .findALabControls.findalab-controls-mobile {
        position: relative !important;
      }

      .findALabContainer.findalab-container-mobile,
      .findALabControls.findalab-controls-mobile {
        height: auto !important;
      }

      .findALabContainer.findalab-container-mobile {
        height: auto !important;
        display: flex !important;
        flex-direction: column-reverse !important;

        findalab-results {
          div.resultsContainer {
            height: auto !important;
            max-height: 485px !important;
            border: 1px $platinum solid;
            border-bottom: none;

            div.init_message {
              width: auto !important;
            }
          }
        }
      }

      div.findALabMap {
        min-height: 500px !important;
        position: relative !important;
        width: 100% !important;
        margin-top: 10px;
      }

      .findalab__box__top {
        border: solid 1px $platinum;
      }

      .findalab__total {
        border: 1px $platinum solid !important;
      }

      .resultsContainer {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      .in-home-booking-date-and-slot .in-home-booking-slot {
        flex-grow: 1;
      }
    }
  }
}

app-treatmyuti-layout {
  .findALabContainer {
    button,
    input {
      font-family: Nunito, serif;
    }

    &.findalab-container-mobile {
      .findALabMap {
        box-shadow: 0px 0px 5px $pearly-purple;
      }
    }
  }

  .findalab__search {
    margin-bottom: 0;
  }

  .findalab__search__title {
    font-size: 26px;
    font-weight: 700;
  }

  .findalab__search__user-location {
    margin-bottom: 12px;
    color: $pearly-purple;
    font-size: 16px;
    padding-left: 0px;
  }

  .findalab_searchForm {
    > input {
      border-radius: 16px;
      border: 1px solid $pearly-purple;
      height: 40px;
      padding-right: 96px !important;
      font-size: 16px !important;
    }

    button[type='submit'] {
      font-size: 20px;
      background-color: $pearly-purple;
      margin-left: -80px;
      border-radius: 16px !important;

      &:hover {
        background-color: $pink-lavender;
        color: $pearly-purple !important;
      }
    }
  }

  .findalab-controls-mobile {
    .findalab__box__top,
    .resultsContainer {
      box-shadow: 0px 0px 5px $pearly-purple;
    }

    .findalab__nav {
      .findalab__nav__item {
        color: $pearly-purple;
        border: 2px solid $pearly-purple;

        &.is-active {
          background-color: $pearly-purple !important;
        }
      }
    }
  }

  .result-container {
    h4 {
      font-weight: 700;
      line-height: 1.2;
    }

    > div {
      margin-bottom: 8px;
      line-height: 1.42;
    }

    button[type='button'] {
      text-transform: uppercase;
      background-color: $pearly-purple;
      border-radius: 16px;
      min-height: 36.8px;

      &:hover {
        background-color: $pink-lavender;
        color: $pearly-purple !important;
      }
    }

    table.daysOfWeek {
      tbody {
        tr {
          td,
          th {
            div.closedForLunch {
              font-size: 0.68rem !important;
            }
          }
          th {
            padding-left: 0.5rem !important;
            padding-right: 0 !important;
          }
          td {
            padding-left: 0 !important;
            padding-right: 0.5rem !important;
            div {
              font-size: 0.8rem !important;
              text-align: right !important;
            }
          }
        }
      }
    }
  }

  .showHideHours {
    color: $pearly-purple;

    &:hover {
      text-decoration: underline;
    }
  }

  .findalab-popup-button {
    background-color: $pearly-purple;
    text-transform: uppercase;
    border-radius: 16px;

    &:hover {
      background-color: $pink-lavender;
      color: $pearly-purple;
    }
  }

  .findalab__search__day-filter {
    input[type='radio']:checked ~ .findalab__radioButton {
      border: 2px solid $pearly-purple;
    }

    input[type='radio']:checked ~ .findalab__radioButton:before {
      background: $pearly-purple;
    }

    div.findalab__radioButton {
      border: 2px solid $pearly-purple;
    }
  }
}

app-starfish-layout {
  .findALabContainer {
    button,
    input {
      font-family: Poppins, serif;
    }

    &.findalab-container-mobile {
      .findALabMap {
        box-shadow: 0px 0px 5px $pastel-red;
      }
    }
  }

  .findalab__search__title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 0.6em;
  }

  .findalab__search__desc {
    font-size: 15px;
    text-align: center;
    line-height: 24px;
  }

  .findalab__search__user-location {
    width: 100%;
    margin-bottom: 24px;
    color: $dark-charcoal;
    text-align: center;
    font-size: 15px;
    font-weight: 600;

    i {
      color: $pastel-red;
    }
  }

  .findalab_searchForm {
    > input {
      border-radius: 8px;
      border: 1px solid $pastel-red;
      height: 60px;
      padding-right: 106px !important;
      font-size: 15px !important;
    }

    button[type='submit'] {
      font-size: 15px;
      background-color: $pastel-red;
      border-radius: 8px !important;
      margin-left: -90px;

      &:hover {
        background-color: $orange-red-crayola;
      }
    }
  }

  .findalab-controls-mobile {
    .findalab__box__top,
    .resultsContainer {
      box-shadow: 0px 0px 5px $pastel-red;
    }

    .findalab__nav {
      .findalab__nav__item {
        color: $pastel-red;
        border: 2px solid $pastel-red;

        &.is-active {
          background-color: $pastel-red !important;
        }
      }
    }
  }

  .result-container {
    h4 {
      font-weight: 600;
      line-height: 32px;
    }

    > div {
      margin-bottom: 4px;
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;

      strong {
        font-weight: normal;
      }
    }

    button[type='button'] {
      color: $white;
      background-color: $pastel-red;
      border-radius: 8px;
      min-height: 44px;
      margin-top: 8px;

      &:hover {
        background-color: $orange-red-crayola;
      }
    }
  }

  .findalab-popup-button {
    background-color: $pastel-red;
    border-radius: 8px;

    &:hover {
      background-color: $orange-red-crayola;
    }
  }

  .findalab__search__day-filter {
    input[type='radio']:checked ~ .findalab__radioButton {
      border: 2px solid $pastel-red;
    }

    input[type='radio']:checked ~ .findalab__radioButton:before {
      background: $pastel-red;
    }

    div.findalab__radioButton {
      border: 2px solid $pastel-red;
    }
  }
}

app-treatmyuti-layout,
app-starfish-layout,
app-stdcheck-layout app-lab-finder-modal,
app-healthlabs-layout app-lab-finder-modal {
  .field.has-addons .findalab_searchForm {
    width: 100%;
  }

  .findALabContainer {
    height: 816px !important;
    width: calc(100% - 24px) !important;
    margin: 12px;
    margin-bottom: 0;

    &.findalab-container-mobile {
      display: flex;
      flex-flow: column-reverse nowrap;
      gap: 16px;
      padding: 0 12px;
      height: auto !important;
      width: 100% !important;
      margin: 0 !important;
      margin-top: 12px !important;

      .findALabMap {
        position: relative !important;
        height: 486px !important;
      }

      findalab-results {
        overflow-y: visible;
      }
    }
  }

  .findALabControls {
    height: 784px !important;
  }

  .findalab-controls-mobile {
    height: auto !important;
    position: relative !important;

    .resultsContainer {
      height: 486px !important;
    }

    .findalab__nav {
      display: flex;
      flex-flow: row nowrap;
      margin-top: 12px;
      gap: 8px;

      .findalab__nav__item {
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        border-radius: 9px;
        font-size: 14px;
        font-weight: 700;
        padding: 6px 0;
        background-color: $white !important;

        &.is-active {
          color: $white;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .findalab__search__desc {
    margin-bottom: 12px !important;
  }

  .findalab__search__user-location {
    background-color: $white;
    border: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    i {
      margin-right: 6px;
    }
  }

  .findalab_searchForm {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    > input {
      min-width: 0px;
      flex-grow: 1;
      padding-left: 16px;

      &::placeholder {
        color: $placeholder-gray;
        opacity: 1;
      }
    }

    button[type='submit'] {
      font-weight: 700;
      color: $white;
      height: 40px;
      width: 80px;
      border: none;
    }
  }

  .resultsContainer {
    border-radius: 6px;
    height: 100% !important;

    > .init_message {
      width: auto !important;
    }
  }

  .findalab__total {
    display: none;
  }

  .result-container {
    h4 {
      font-size: 20px;
      margin-bottom: 12px;
    }

    button[type='button'] {
      color: $white;
      font-weight: 700;
      width: 100%;
      border: none;
      font-size: 16px;

      &:hover {
        cursor: pointer;
      }

      i {
        margin-left: 6px;
      }
    }
  }

  .findalab-popup-content {
    h4 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 12px;
    }

    > div {
      margin-bottom: 4px;
    }
  }

  .findalab-popup-button {
    color: $white;
    font-weight: 700;
    font-size: 10px;
    padding: 6.5px 13.5px;
    margin-top: 12px;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  .findalab__search__day-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 1rem;
    margin-top: 1rem;

    label {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 0.2rem;
      cursor: pointer;
    }

    input[type='radio'] {
      position: absolute;
      visibility: hidden;
    }

    div.findalab__radioButton {
      border-radius: 100%;
      display: block;
      height: 16px;
      width: 16px;

      &::before {
        border-radius: 100%;
        content: '';
        display: block;
        height: 10px;
        width: 10px;
        top: 1px;
        position: relative;
        margin: auto;
      }
    }
  }
}

app-stdcheck-layout,
app-healthlabs-layout {
  app-lab-finder-modal {
    .findalab_searchForm {
      > input {
        border-radius: 8px;

        height: 40px;
        padding-right: 96px !important;
        font-size: 16px !important;
      }

      button[type='submit'] {
        font-size: 20px;
        margin-left: -80px;
        border-radius: 8px !important;
      }
    }

    .findalab__search__user-location {
      margin-bottom: 12px;
      font-size: 16px;
      padding-left: 0px;
    }

    .findalab__search__title {
      font-size: 20px;
      font-weight: 700;
    }
  }
}

app-stdcheck-layout app-lab-finder-modal {
  .findALabContainer.findalab-container-mobile .findALabMap {
    box-shadow: 0px 0px 5px $primary;
  }

  .findalab_searchForm > input {
    border: 1px solid $orange;
  }

  .findalab-controls-mobile {
    .findalab__box__top,
    .resultsContainer {
      box-shadow: 0px 0px 5px $primary;
    }

    .findalab__nav .findalab__nav__item {
      color: $primary;
      border: 2px solid $primary;

      &.is-active {
        background-color: $primary !important;
      }
    }
  }

  .findalab__search__user-location {
    color: $primary;
  }

  .findalab__search__day-filter {
    input[type='radio']:checked ~ .findalab__radioButton {
      border: 2px solid $orange;
    }

    input[type='radio']:checked ~ .findalab__radioButton:before {
      background: $orange;
    }

    div.findalab__radioButton {
      border: 2px solid $orange;
    }
  }
}

app-healthlabs-layout app-lab-finder-modal {
  .findALabContainer.findalab-container-mobile .findALabMap {
    box-shadow: 0px 0px 5px $sea-green;
  }

  .findalab_searchForm > input {
    border: 1px solid $lapis-lazuli;
  }

  .findalab-controls-mobile {
    .findalab__box__top,
    .resultsContainer {
      box-shadow: 0px 0px 5px $sea-green;
    }

    .findalab__nav .findalab__nav__item {
      color: $sea-green;
      border: 2px solid $sea-green;

      &.is-active {
        background-color: $sea-green !important;
      }
    }
  }

  .findalab__search__user-location {
    color: $sea-green;
  }

  .findalab__search__day-filter {
    input[type='radio']:checked ~ .findalab__radioButton {
      border: 2px solid $lapis-lazuli;
    }

    input[type='radio']:checked ~ .findalab__radioButton:before {
      background: $lapis-lazuli;
    }

    div.findalab__radioButton {
      border: 2px solid $lapis-lazuli;
    }
  }
}
